import { TYPO } from "../config"

interface TypoProps {
	variant: TYPO
	className?: any
	children: any
}

const Typo: React.FC<TypoProps> = ({ variant, className, children }) => {
	switch (variant) {
		case TYPO.H1:
			return (
				<h1 className={"mb-4 md:mb-12 font-Chromate text-white text-5xl xl:text-7xl " + (className ? className : "")}>
					{children}
				</h1>
			)
		case TYPO.H2:
			return (
				<h2 className={"mb-4 md:mb-12 font-Chromate text-white text-5xl " + (className ? className : "")}>
					{children}
				</h2>
			)
		case TYPO.H3:
			return <div className={"font-Arboria text-white text-xl " + (className ? className : "")}>{children}</div>

		default:
			return <>children</>
	}
}

export default Typo
