function Footer() {
	type TFooterLink = {
		text: string
		link: string
	}

	const footerLinks: TFooterLink[] = [
		{
			text: "Impressum",
			link: process.env.PUBLIC_URL + "/impressum.php",
		},
		{
			text: "Datenschutz",
			link: process.env.PUBLIC_URL + "/datenschutz.php",
		},
		{
			text: "Xing",
			link: "https://www.xing.com/profile/Bjoern_Ruehle",
		},
	]

	return (
		<footer className="bg-white text-center py-6 z-10 relative">
			<nav>
				<ul className="ml-0  block md:inline-block">
					{footerLinks.map((footerLink, i) => (
						<li key={i} className="mr-6 block md:inline-block my-3 md:my-0">
							<a
								href={footerLink.link}
								target="_blank"
								rel="noreferrer"
								className="text-brightYellow font-normal text-base "
							>
								{footerLink.text}
							</a>
						</li>
					))}
				</ul>
				<button
					onClick={() => {
						window.scrollTo({
							top: 0,
							left: 0,
							behavior: "smooth",
						})
					}}
					className="mt-6 md:my-0 border-2 rounded-lg px-4 py-2 border-black/30"
				>
					Nach oben
					<svg
						className="inline-block w-6 ml-2 top-[-4px] relative"
						focusable="false"
						aria-hidden="true"
						viewBox="0 0 24 24"
						data-testid="NorthIcon"
					>
						<path d="m5 9 1.41 1.41L11 5.83V22h2V5.83l4.59 4.59L19 9l-7-7-7 7z" fill="rgb(43 59 53 / 0.3)" />
					</svg>
				</button>
			</nav>
		</footer>
	)
}

export default Footer
