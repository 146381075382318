import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import TagManager from "react-gtm-module"
import { TYPO } from "../config"
import Typo from "../elements/Typo"

const Privacy: React.FC = () => {
	// GTM

	const tagManagerArgs = {
		dataLayer: {
			page: "erweiterterdatenschutz",
		},
		dataLayerName: "dataLayer",
	}
	TagManager.dataLayer(tagManagerArgs)

	// SEO Meta Tags

	const meta = {
		title: "Mit merci zum Muttertag filmreif Danke sagen!",
		canonical: "https://arvato-lesemaus.de/smarties",
		meta: {
			name: {
				robots: "noindex, nofollow",
			},
		},
	}

	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		})
	}, [])

	return (
		<DocumentMeta {...meta}>
			<section className="bg-brightYellow py-12 md:py-[80px]">
				<div className="container max-w-[970px] mx-auto px-4  " id="anchor-so-gehts">
					<Typo variant={TYPO.H1} className="mb-16">
						Erweiterte Datenschutzerklärung “Deine smarties Lesemaus”
					</Typo>
				</div>
			</section>
		</DocumentMeta>
	)
}
export default Privacy
