import React from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import Footer from "./components/Footer"
import Home from "./nav/Home"
import Privacy from "./nav/Privacy"
import Terms from "./nav/Terms"

const App = () => {
	return (
		<BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
			<main className="bg-cyan">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/datenschutz" element={<Privacy />} />
					<Route path="/teilnahmebedingungen" element={<Terms />} />
				</Routes>
			</main>
			<Footer />
		</BrowserRouter>
	)
}

export default App

// TODO: name status missing for graylist
