import React from "react"

interface SectionItemProps {
	children?: any
}

const SectionItem: React.FC<SectionItemProps> = ({ children }) => {
	return <section className="relative z-10 my-12 md:my-0 md:min-h-screen  flex items-center">{children}</section>
}
export default SectionItem
