import React from "react"

interface SkillitemProps {
	children?: any
	small?: boolean
}

const SkillItem: React.FC<SkillitemProps> = ({ children, small }) => {
	return (
		<li
			className={
				"bg-white text-black  skew-y-[10deg] -rotate-[10deg] outline-2 outline-black outline inline-block  whitespace-nowrap outline-offset-[-4px] italic  font-Arboria " +
				(small ? "text-lg py-1 px-4 m-1 " : "text-2xl py-2 px-6 m-3 ")
			}
		>
			{children}
		</li>
	)
}
export default SkillItem
