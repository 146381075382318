import React, { useEffect } from "react"
import DocumentMeta from "react-document-meta"
import { TYPO } from "../config"
import SectionItem from "../elements/SectionItem"
import SkillItem from "../elements/Skillitem"
import Typo from "../elements/Typo"

import Plx from "react-plx"
import HR from "../elements/Hr"

const Home: React.FC = () => {
	// GTM

	// SEO Meta Tags

	const meta = {
		title: "React Entwickler Hamburg - Björn Rühle",
		canonical: "https://webstanding.de",
		meta: {
			name: {
				// robots: "noindex, nofollow",
			},
		},
	}

	useEffect(() => {}, [])

	const parallaxData = [
		{
			start: 0,
			end: "footer",
			properties: [
				{
					startValue: 0,
					endValue: 180,
					property: "rotate",
				},
			],
		},
	]

	return (
		<DocumentMeta {...meta}>
			<Plx className="fixed left-0 top-0 w-full h-full z-0" parallaxData={parallaxData}>
				<div
					style={{ transform: "scale(2.5)" }}
					className="bg-[url('/src/images/pattern.svg')]  bg-no-repeat absolute left-0 top-0 w-full h-full"
				></div>
			</Plx>

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4 w-full">
					<Typo variant={TYPO.H1} className="text-center">
						{/* Meine Skills */}
						Björn Rühle
					</Typo>
					<div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto px-4 items-center">
						<ul className="list-skills col-span-2 order-2 md:order-1">
							<SkillItem>Development</SkillItem>
							<SkillItem>Webdesign</SkillItem>
							<SkillItem>UX</SkillItem>
							<SkillItem>Apps</SkillItem>
							<SkillItem>Digitalisierung</SkillItem>
							<SkillItem>Automatisierung</SkillItem>
							{/* <SkillItem>REACT DEVELOPMENT</SkillItem>
							<SkillItem>TYPESCRIPT</SkillItem>
							<SkillItem>ONPAGE PERFORMANCE</SkillItem>
							<SkillItem>SUITCSS / Tailwind</SkillItem>
							<SkillItem>
								PROGR<span className="hidden sm:inline">ESSIVE</span>
								<span className="inline sm:hidden">.</span> ENHANCEMENT
							</SkillItem>
							<SkillItem>AGILE WORKFLOWS</SkillItem> */}
						</ul>
						<div className="order-1 md:order-2">
							<img
								className="rounded-[50%] border-2 border-black mx-auto max-w-[70%]"
								src="/images/bjoern_ruehle.jpg"
								style={{ boxShadow: "0px 0px 0px 4px #ffffff" }}
								alt="Ich"
								width="1000"
								height="954"
							/>
						</div>
					</div>
				</div>
				<div className="absolute right-4 bottom-4 w-28 hidden md:block">
					<svg
						className="w-full gelatine"
						fill="#000000"
						version="1.1"
						id="Capa_1"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 552.855 552.855"
					>
						<g>
							<g>
								<path
									fill="white"
									d="M511.889,157.413c-3.408-25.839-17.051-53.507-39.994-76.445c-22.949-22.95-50.619-36.598-76.463-40
			c-11.695-1.542-27.295-8.005-36.652-15.184C338.094,9.915,308.883,0,276.428,0s-61.665,9.915-82.351,25.784
			c-9.357,7.179-24.964,13.642-36.653,15.184c-25.845,3.403-53.513,17.05-76.463,40c-22.944,22.938-36.591,50.606-39.994,76.445
			c-1.542,11.695-8.005,27.308-15.184,36.665C9.915,214.763,0,243.974,0,276.428c0,32.455,9.915,61.666,25.784,82.352
			c7.179,9.357,13.642,24.975,15.184,36.664c3.409,25.84,17.05,53.508,39.994,76.445c22.95,22.949,50.619,36.598,76.463,40
			c11.695,1.543,27.295,8.006,36.653,15.184c20.686,15.869,49.896,25.783,82.351,25.783s61.666-9.914,82.351-25.783
			c9.357-7.178,24.963-13.641,36.652-15.184c25.844-3.402,53.514-17.051,76.463-40c22.943-22.938,36.586-50.605,39.994-76.445
			c1.543-11.695,8.006-27.307,15.184-36.664c15.869-20.686,25.783-49.896,25.783-82.352c0-32.454-9.914-61.665-25.783-82.35
			C519.895,184.72,513.432,169.102,511.889,157.413z M413.008,311.006L293.436,430.578c-9.395,9.395-24.621,9.395-34.015,0
			L139.848,311.006c-9.394-9.395-6.242-17.008,7.044-17.008h45.643V124.854c0-5.312,4.309-9.621,9.621-9.621h148.54
			c5.311,0,9.619,4.309,9.619,9.621v169.144h45.643C419.244,293.998,422.402,301.611,413.008,311.006z"
								/>
							</g>
						</g>
					</svg>
				</div>
			</SectionItem>

			<HR />

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4">
					<Typo variant={TYPO.H1}>Gewusst wie</Typo>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Technologien</Typo>
						<ul>
							<SkillItem small={true}>Typescript</SkillItem>
							<SkillItem small={true}>React</SkillItem>
							<SkillItem small={true}>NextJS</SkillItem>
							<SkillItem small={true}>Tailwind</SkillItem>
							<SkillItem small={true}>Flutter</SkillItem>
						</ul>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Responsive Web Design</Typo>
						<ul>
							<SkillItem small={true}>Cross Browser Testing </SkillItem>
							<SkillItem small={true}>Emulator </SkillItem>
							<SkillItem small={true}>Real Device Testing</SkillItem>
						</ul>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Attraktive UX</Typo>
						<ul>
							<SkillItem small={true}>Styleguides </SkillItem>
							<SkillItem small={true}>BITV </SkillItem>
							<SkillItem small={true}>Transitions </SkillItem>
							<SkillItem small={true}>Animationen</SkillItem>
						</ul>
					</div>
					<div className="grid grid-cols-1 sm:grid-cols-[1fr_2fr] gap-3 md:gap-8 mb-8 ">
						<Typo variant={TYPO.H3}>Agile Entwicklungs­methoden</Typo>
						<ul>
							<SkillItem small={true}>Scrum Kanban </SkillItem>
							<SkillItem small={true}>Continuous Delivery</SkillItem>
						</ul>
					</div>
				</div>
			</SectionItem>

			<HR />

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4">
					<Typo variant={TYPO.H1}>Kunden & Brands</Typo>
					<div className="grid grid-cols-2 md:grid-cols-4 gap-0 md:gap-8">
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-1.png"
								alt="Kunde 1"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-2.png"
								alt="Kunde 2"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-3.png"
								alt="Kunde 3"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
						<SkillItem>
							<img
								src="/images/frontend-entwickler-hh-ref-4.png"
								alt="Kunde 4"
								className="p-4 bg-white rotate-[10deg] -skew-y-[10deg]"
								width="700"
								height="504"
							/>
						</SkillItem>
					</div>
				</div>
			</SectionItem>

			<HR />

			<SectionItem>
				<div className=" max-w-5xl mx-auto px-4">
					<div className="grid md:grid-cols-[250px_1fr] gap-8 items-center">
						<svg
							className="hidden md:block"
							focusable="false"
							aria-hidden="true"
							viewBox="0 0 24 24"
							data-testid="ContactMailIcon"
						>
							<path
								fill="#ffffff"
								d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
							/>
						</svg>

						<div>
							<Typo variant={TYPO.H1}>Kontakt</Typo>

							<svg
								className="block md:hidden  mx-auto max-w-[60%]"
								focusable="false"
								aria-hidden="true"
								viewBox="0 0 24 24"
								data-testid="ContactMailIcon"
							>
								<path
									fill="#ffffff"
									d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8 8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5 2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47.65.89 1.77 1.47 2.96 1.47 1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"
								/>
							</svg>
							<p className="text-white text-xl mb-4 md:mb-8 ">
								Sie haben eine Frage oder benötigen individuelle Unterstützung bei Ihrem Projekt? Dann schreiben Sie mir
								gerne eine Nachricht mit Ihrem Anliegen!
							</p>
							<a
								href="mailto:moin@webstanding.de"
								className="rounded-lg border-2 border-white text-white text-lg px-8 py-4 inline-block"
							>
								Anfrage senden
							</a>
						</div>
					</div>
				</div>
			</SectionItem>
		</DocumentMeta>
	)
}
export default Home
